<template>
  <div>
    <vs-popup title="Edit User" class="sm:popup-w-50" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="con-vs-alert con-vs-alert-primary con-icon">
              <div class="vs-alert con-icon">
                <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
                Isi kolom password hanya jika ingin mengubah password.
              </div>
            </div>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <vs-input class="w-full" label="Nama *" v-model="data.name"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Role *</label>
            <vs-select autocomplete class="w-full" v-model="data.id_roles" :disabled="data.type === 'REKANAN'">
              <vs-select-item v-for="(it, index) in roles" :key="index" :value="it.id" :text="it.name"/>
            </vs-select>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" label="Username *" autocomplete="new-password" v-model="data.username"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" label="Password" autocomplete="new-password" v-model="data.password"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import AclRepository from '@/repositories/general/acl-repository'
import _ from 'lodash'

export default {
  name: 'UserEdit',
  props: ['isActive', 'item'],
  components: {
    ValidationErrors: () => import('@/views/components/validation-errors/ValidationErrors')
  },
  watch: {
    isActive (value) {
      if (value === true) this.data = _.cloneDeep(this.item)
    }
  },
  computed: {
    roles () {
      return this.$store.state.general.accessControl.roles
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {}
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      const params = {
        id: this.data.id,
        nama: this.data.name,
        id_roles: this.data.id_roles,
        username: this.data.username,
        password: this.data.password
      }

      AclRepository.updateUser(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
